import React, { useState } from 'react';
import {
  Accordion as RawAccordion,
  AccordionItem as RawItem,
  AccordionButton as RawButton,
  AccordionPanel as RawPanel,
} from '@reach/accordion';
import '@reach/accordion/styles.css';
import { get } from 'lodash';
import styled, { keyframes } from 'styled-components';
import IcGreenDropDown from '../assets/images/icons/IcGreenDropDown';
import Content from 'components/Content';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
`;

const AccordionButton = styled(RawButton)`
  width: 100%;
  height: 100%;
  border: solid 1px rgba(0, 0, 0, 0.12);
  border-radius: 4px 4px 0 0;
  text-align: left;
  background-color: transparent;
  font-size: ${({ theme }) => theme.spacing()};

  padding: ${({ theme }) => theme.spacing(1.5)};
  ${({ isSelected }) =>
    isSelected &&
    `
    border-bottom: none;
  
  `}

  svg {
    float: right;
    position: relative;
    left: 20px;
    bottom: 0px;
    animation: ${({ isSelected }) => isSelected && rotation} 0.75s ease;
    ${({ isSelected }) =>
      isSelected &&
      `
      transform: rotate(180deg);
      `}
  }

  &:focus {
    outline: none;
  }
`;

const Accordion = styled(RawAccordion)`
  top: 0;
  width: 66%;
  font-family: 'Muli';
`;

const AccordionPanel = styled(RawPanel)`
  padding: ${({ theme }) => theme.spacing(1.5)};
  ${({ isSelected }) =>
    isSelected &&
    `
    border: solid 1px rgba(0, 0, 0, 0.12);
    border-radius: 0 0 4px 4px;
    border-top: none;
  `}
`;

const AccordionItem = styled(RawItem)`
  margin-bottom: ${({ theme }) => theme.spacing()};
`;
const MainTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const FaqAccordion = ({ questions }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const isSelected = index => selectedIndex === index;

  const Title = get(questions, [0, 'group', 'translations', 'displayName'], '');

  return (
    <>
      <Accordion defaultIndex={0}>
        <MainTitle>{Title}</MainTitle>
        {(questions || []).map(({ translations, answer }, index) => (
          <AccordionItem isSelected={isSelected(index)}>
            <AccordionButton
              onClick={() => setSelectedIndex(index)}
              isSelected={isSelected(index)}
            >
              {translations.displayName}
              <IcGreenDropDown />
            </AccordionButton>
            <AccordionPanel isSelected={isSelected(index)}>
              <Content html={get(answer, 'translations.displayName', '')} />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};

export default FaqAccordion;
