import * as React from 'react';

function IcGreenDropDown(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        fill="#73C992"
        fill-rule="evenodd"
        d="M6.645 8L12 12.945 17.355 8 19 9.522 12 16 5 9.522z"
      />
    </svg>
  );
}

export default IcGreenDropDown;
