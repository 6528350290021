import React, { useState } from 'react';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import { Container } from '../ui/containers';
import { HeroHeader } from '../ui';
import faqBanner from '../assets/images/faqBanner.jpg';
import styled from 'styled-components';
import FaqAccordion from '../components/FaqAccordion';
import FaqSection from '../components/FaqSection';
import { useAsync } from 'react-async';
import { getQuestions } from 'api/faq';
import { groupBy, values } from 'lodash';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 224px;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;

const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.text};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const FAQ = () => {
  const { t } = useTranslation();
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);

  const { data: { 'faq/questions': questions } = {} } = useAsync({
    promiseFn: getQuestions,
  });

  const sortQuestions = values(groupBy(questions, ({ group }) => group.id));

  return (
    <Layout>
      <SEO title={t('pageTitle.faq')} />
      <HeroHeader picture={faqBanner}>
        <h1>{t('faq.title')}</h1>
      </HeroHeader>
      <Container>
        <Wrapper>
          <SectionsWrapper>
            <SectionTitle>{t('faq.mainTitle')}</SectionTitle>
            {(sortQuestions || []).map((group, index) => (
              <SectionWrapper>
                <FaqSection
                  group={group[0].group}
                  index={index}
                  setSelectedGroupIndex={setSelectedGroupIndex}
                  isSelected={selectedGroupIndex === index}
                />
              </SectionWrapper>
            ))}
          </SectionsWrapper>
          <FaqAccordion questions={sortQuestions[selectedGroupIndex]} />
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default FAQ;
