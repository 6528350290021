import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';

const Group = styled.div`
  color: ${({ theme, isActive }) => (isActive ? theme.green : theme.text)};
  transition: color 150ms ease;
  cursor: pointer;
`;

const FaqSection = ({ group, index, setSelectedGroupIndex, isSelected }) => {
  const name = get(group, 'translations.displayName', '');
  return (
    <Group isActive={isSelected} onClick={() => setSelectedGroupIndex(index)}>
      {name}
    </Group>
  );
};

export default FaqSection;
